<template>
  <div id="menu">
    <CustomMenu v-if="!pending" :menus="menus" />
  </div>
</template>

<script setup>
const { locale } = useI18n();
const props = defineProps({ pagina: String });
const { data: menus, pending } = await useFetch(`/api/site/menu`, {
  headers: { "Content-Language": locale },
});
</script>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Menus",
};
</script>
