<template>
  <nav class="navbar navbar-expand-lg menu">
    <div class="container-fluid">
      <NuxtLink :to="localePath('/', locale)">
        <CustomPicture
          class="px-3 py-3"
          :imagem="`logo-sorrifacil-branco-${locale}`"
          width="250"
          height="100"
        />
      </NuxtLink>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav me-right mb-2 mb-lg-0">
          <span v-for="menu in menus" v-bind:key="menu?.link">
            <li v-if="menu.submenu" class="nav-item dropdown">
              <NuxtLink
                class="nav-link menu-item"
                to="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {{ $t(menu.texto) }}
              </NuxtLink>
              <ul
                class="dropdown-menu submenu"
                aria-labelledby="navbarDropdown"
              >
                <li v-for="submenu in menu.submenu" v-bind:key="submenu?.link">
                  <NuxtLink
                    class="dropdown-item menu-item submenu-item"
                    :to="localePath(`/${menu.link}/${submenu.link}`, locale)"
                  >
                    {{ submenu.texto }}
                  </NuxtLink>
                </li>
              </ul>
            </li>
            <li v-else class="nav-item">
              <NuxtLink
                v-if="menu.mostra"
                class="nav-link menu-item"
                aria-current="page"
                :to="localePath(menu.link, locale)"
              >
                {{ $t(menu.texto) }}
              </NuxtLink>
            </li>
          </span>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup>
const { locale } = useI18n();
const localePath = useLocalePath();
const props = defineProps({
  menus: Array,
});
</script>

<script>
export default {
  name: "CustomMenu",
};
</script>
